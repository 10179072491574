import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import envs from '../lib/envs.server';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useDeployEnv() {
  return envs.DEPLOY_ENV || window.ENV?.DEPLOY_ENV;
}
